<template>
  <div class="prompts-details">

    <PromptsMenus
        class="prompts-menus"
        :tree="menus"
        :selectedKeys="selectedKeys"
        :openedKeys="openedKeys"
        @menuChange="menuChange"/>

    <div class="prompts-right-container">
      <div class="content-container">
        <div v-for="item in content" class="content-card" @click="clickCard(item)">
          <p>{{ item.title }}</p>
          <p class="description">{{ item.description }}</p>
          <a-icon type="eye" style="font-size: 14px;"/>
          <span>{{ item.useNum }}</span>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {details, knowledge} from "@/api/knowledge";

export default ({
  components: {
    PromptsMenus: () => import('@/views/application/chat/components/PromptsMenus.vue')
  },
  data() {
    return {
      name: '',
      content: [],
      menus: [],
      selectedKeys: [],
      openedKeys: []
    }
  },
  methods: {
    init() {
      knowledge().then(res => {
        if (res.data) {
          this.menus = res.data
          this.selectedKeys.push(res.data[0].children[0].id)
          this.openedKeys.push(res.data[0].id)
          this.menuChange(res.data[0].children[0])
        }
      })
    },
    clickCard(card){
      this.$emit('clickCard', card)
    },
    menuChange(node) {
      this.name = node.name
      details(node.key || node.id).then(res => {
        this.content = res.data
      })
    },

  },
  mounted() {
    this.init()
  }

})
</script>


<style scoped lang="scss">
.prompts-details {
  height: 100%;
  display: flex;

  .prompts-menus {
    width: 240px;
    height: 100%;
    overflow-y: auto;

  }

  .prompts-right-container {
    height: 100%;
    background: #fff;
    width: calc(100% - 240px);
    overflow-y: auto;

    .content-container {
      padding: 0 20px 10px 20px;

      .content-card {
        border-radius: 10px;
        border: 1px solid #f1f1f1;
        margin-top: 20px;
        padding: 15px;
        cursor: pointer;

        p:nth-child(1) {
          font-size: 16px;
          font-weight: 600;
        }

        p:nth-child(2) {
          color: #8C8C8C;
        }
      }

      .content-card:hover {
        p:nth-child(1) {
          color: #1890FF;
        }
      }
    }

  }
}


::v-deep.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(0, 92, 253, 0.09);
}

::v-deep.ant-menu-item-active {
  background: black;
}
</style>